import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UspsLanderComponent } from 'components/usps-lander/usps-lander.component';
import { UpsLanderComponent } from 'components/custom/eddm/ups-lander/ups-lander.component';
import { LoginCallbackComponent } from 'components/shared/register-form/login-callback/login-callback.component';
import { LoginSilentComponent } from 'components/shared/register-form/login-silent/login-silent.component';
import { SignOutComponent } from 'components/shared/register-form/sign-out/sign-out.component';
import { LogoutCallbackComponent } from 'components/shared/register-form/logout-callback/logout-callback.component';


let routes: Routes = [
	{ path: 'USPSRef.aspx', component: UspsLanderComponent },
	{ path: 'ups', component: UpsLanderComponent },
	{ path: 'InboundRef.aspx', component: UspsLanderComponent },
	{ path: 'login-callback', component: LoginCallbackComponent },
	{ path: 'login-silent', component: LoginSilentComponent },
	{ path: 'sign-out', component: SignOutComponent },
	{ path: 'logout-callback', component: LogoutCallbackComponent },
	// logout-callback
	{ path: '', loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule) },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: "enabled",
		anchorScrolling: 'enabled',
		onSameUrlNavigation: 'reload'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
